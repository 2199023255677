<template>
  <div class="client page">
    <div class="content">
      <div class="page_header">
        <h1>Клиент</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Client",
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
